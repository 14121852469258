import Pushy from 'pushy-sdk-web';
import api from './api';

export const enablePersonalNotifications = async ({ onAuthFail }) => {
  if (!Pushy.isRegistered()) {
    await register();
  }

  const deviceToken = localStorage.getItem('pushyToken');

  try {
    await api.post('/devicetokens', { token: deviceToken }, { skipAuthInterceptor: true });

    localStorage.setItem('isPersonalNotificationsEnabled', 'true');
  } catch (error) {
    if (error.response && error.response.status === 401) {
      onAuthFail && onAuthFail();

      return;
    }

    throw error;
  }
};

const register = async () => {
  try {
    const token = await Pushy.register({ appId: process.env.REACT_APP_PUSHY_APP_ID });
    await Pushy.subscribe('silent-client');
    return token;
  } catch (error) {
    console.error('Failed to register the device token', error);
    localStorage.setItem('isPersonalNotificationsEnabled', 'false');
    localStorage.setItem('topicsState', JSON.stringify(topicsState));
    throw error;
  }
};

export const disablePersonalNotifications = async () => {
  const deviceToken = localStorage.getItem('pushyToken');

  if (deviceToken) {
    await api.delete(`/devicetokens/${deviceToken}`, { skipAuthInterceptor: true });

    localStorage.setItem('isPersonalNotificationsEnabled', 'false');
  }
};

export const isPersonalNotificationsEnabled = () => localStorage.getItem('isPersonalNotificationsEnabled') === 'true';

const topicsState = {
  'cat-1': false,
  'cat-2': false,
  'cat-3': false,
  'cat-4': false,
  'cat-5': false,
  'cat-6': false,
  'cat-7': false,
  'cat-8': false,
  'cat-9': false,
  'cat-10': false,
  'cat-11': false,
  'cat-12': false,
  'cat-13': false,
  'cat-14': false,
  'cat-15': false,
  'cat-16': false,
  'cat-17': false,
  'cat-18': false,
  'cat-19': false,
  'cat-20': false,
  'cat-21': false,
  'cat-22': false,
  'cat-23': false,
  'cat-24': false,
  'cat-25': false,
  'cat-26': false,
};

export const subscribeToTopic = async (updatedTopics) => {
  const currentState = JSON.parse(localStorage.getItem('topicsState')) || topicsState;

  for (let topic in updatedTopics) {
    if (updatedTopics.hasOwnProperty(topic) && currentState.hasOwnProperty(topic)) {
      currentState[topic] = updatedTopics[topic];
    }
  }

  if (!Pushy.isRegistered()) {
    await register();
  }

  const topicsToSubscribe = Object.keys(currentState).filter(topic => currentState[topic]);
  const topicsToUnsubscribe = Object.keys(currentState).filter(topic => !currentState[topic]);

  if (topicsToSubscribe.length > 0)
    await Pushy.subscribe(topicsToSubscribe);

  if (topicsToUnsubscribe.length > 0)
    await Pushy.unsubscribe(topicsToUnsubscribe);

  localStorage.setItem('topicsState', JSON.stringify(currentState));
};

// export const unsubscribeFromAllTopics = async () => {
//   const currentState = JSON.parse(localStorage.getItem('topicsState')) || topicsState;

//   if (Pushy.isRegistered()) {
//     await Pushy.unsubscribe(Object.keys(currentState));
//   }

//   localStorage.setItem('topicsState', JSON.stringify(topicsState));
// };

export const getSubscribedTopics = () => {
  return JSON.parse(localStorage.getItem('topicsState')) || topicsState;
};
