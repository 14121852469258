// pages/EditProfile.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Alert, TextField, Button, Stack, Avatar, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import api from '../services/api';

const EditProfile = () => {

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [photoBlob, setPhotoBlob] = useState(null); // Store the resized image as a Blob
  const [imageSrc, setImageSrc] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await api.get('/users/my');

        const data = response.data;
        setName(data.name);
        setImageSrc(data.photoUrl);
      } catch (error) {
        setError(error.response?.data || error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append("name", name);

      if (photoBlob) {
        formData.append("photo", photoBlob);
      }

      await api.put('/users/my', formData);

      navigate('/profile');
    } catch (error) {
      setError(error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          // Create a canvas element to resize the image
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set the desired width and height (in pixels)
          const maxWidth = 100;
          const maxHeight = 100;

          let width = img.width;
          let height = img.height;

          // Calculate new width and height while maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          // Draw the image on the canvas
          ctx.drawImage(img, 0, 0, width, height);

          // Convert the canvas to a Blob (for uploading)
          canvas.toBlob(
            (blob) => {
              setImageSrc(URL.createObjectURL(blob)); // Preview resized image
              setPhotoBlob(blob); // Store the Blob to upload later
            },
            "image/jpeg",
            0.8 // '0.8' is quality (80%)
          );
        };
      };
    }
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }} >
      <Stack direction='row' spacing={1} alignItems='baseline'>
        <IconButton color='inherit' size='large' onClick={() => navigate(-1)} aria-label="go back">
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h4" gutterBottom>Профиль</Typography>
      </Stack>

      <form onSubmit={handleProfileUpdate}>
        <Stack spacing={2}>
          <Stack alignItems='center' spacing={1}>
            <Avatar
              src={imageSrc}
              sx={{ bgcolor: 'primary.light', width: 100, height: 100 }}
            />

            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              endIcon={<CloudUploadIcon />}>
              Загрузить фотографию
              <VisuallyHiddenInput type="file" accept="image/*" onChange={handleFileChange} />
            </Button>
          </Stack>

          <TextField
            value={name}
            margin="normal"
            required
            fullWidth
            label="Имя"
            onChange={(e) => setName(e.target.value)} />

          {error && <Alert severity="error">{error}</Alert>}

          <Button fullWidth variant='contained' type="submit" disabled={loading}>
            Сохранить
          </Button>
        </Stack>
      </form>

    </Container>
  );
};

export default EditProfile;
