import {
  Alert,
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Link,
  Typography
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Offers = ({ listing }) => {
  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ pt: 1 }} >Предложения</Typography>

      <Alert severity="info" >
        <Typography>Чтобы оставить предложение, <Link href={process.env.REACT_APP_MASTER_URL + '/listing/' + listing.id}>переключитесь в режим мастера</Link>.</Typography>
      </Alert>

      {listing.offers?.length > 0 ? (
        [...listing.offers].sort((a, b) => (a.ratings ?? 0) - (b.ratings ?? 0)).map((offer) => (
          <Card key={offer.id}>
            <CardHeader
              avatar={<Avatar
                sx={{ bgcolor: 'primary.light' }}
                src={offer.photoUrl}
                alt={`${offer.name}`} />}

              title={<Link component={RouterLink} to={`/master/${offer.creatorId}`} >{offer.name}</Link>}
              subheader={offer.ratingsCount > 0
                ? `Рекомендуют ${offer.avarageRating.toFixed(2) * 100}% (${offer.ratingsCount})`
                : 'Нет отзывов'
              }
              action={<Typography variant="caption" color="text.secondary">
                {new Date(offer.createdAt).toLocaleDateString()}
              </Typography>}
            />
            <CardContent>
              {(offer.price && <Typography variant="subtitle1">{offer.price} руб.</Typography>)}
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{offer.description}</Typography>
            </CardContent>
            <CardActions>
              {offer.externalLink && <Link href={offer.externalLink} target="_blank" variant="body1">Перейти на сайт</Link>}
              {offer.phoneNumber &&
                <>
                  <Link href={`tel:${offer.phoneNumber}`} sx={{ mr: 1 }} variant="body1">+{offer.phoneNumber}</Link> /
                  <Link href={`https://wa.me/${offer.phoneNumber}?text=Из%20profisfera.ru/listing/${listing.id}.%20`} variant="body1">WhatsApp</Link>
                </>}
            </CardActions>
          </Card>
        ))
      ) : (
        <Typography>Предложений пока нет</Typography>
      )}
    </>
  );
}

export default Offers;