// pages/InstalliOS.js
import React from 'react';
import { Typography, Container, Stack, IconButton, Box } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';

const InstallApp = () => {
  const navigate = useNavigate();

  const userAgent = window.navigator.userAgent.toLowerCase();
  const ios = /iphone|ipad|ipod/.test(userAgent);
  const samsung = /samsung/.test(userAgent);

  const iosComponent = (
    <>
      <p>
        <Typography variant="body2">Нажмите "Поделиться":</Typography>
        <Box
          component="img"
          src="/ios-share.jpg"
          alt="iOS Share"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body2">Выберите "На экран &laquo;Домой&raquo;":</Typography>
        <Box
          component="img"
          src="/ios-add-to-home.jpg"
          alt="iOS Add to Home"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body2">Иконка появится на домашнем экране:</Typography>
        <Box
          component="img"
          src="/screen.png"
          alt="Home screen"
          sx={{ width: '40%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
    </>
  );

  const chromeComponent = (
    <>
      <p>
        <Typography variant="body2">Нажмите на меню вверху экрана:</Typography>
        <Box
          component="img"
          src="/chrome-menu.png"
          alt="Chrome Menu"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body2">Выберите "Добавить на гл. экран":</Typography>
        <Box
          component="img"
          src="/chrome-add-to-home.png"
          alt="Chrome Add to Home"
          sx={{ width: '60%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body2">Иконка появится на домашнем экране:</Typography>
        <Box
          component="img"
          src="/chrome-icon.png"
          alt="Home screen"
          sx={{ width: '30%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
    </>
  );

  const samsungComponent = (
    <>
      <p>
        <Typography variant="body2">Нажмите на меню внизу экрана:</Typography>
        <Box
          component="img"
          src="/samsung-menu.png"
          alt="Samsung Menu"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body2">Выберите "Добавить страницу":</Typography>
        <Box
          component="img"
          src="/samsung-add-to-home.png"
          alt="Samsung Add to Home"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body2">Выберите "Главный экран":</Typography>
        <Box
          component="img"
          src="/samsung-menu-item.png"
          alt="Samsung Add to Home"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body2">Иконка появится на домашнем экране:</Typography>
        <Box
          component="img"
          src="/samsung-icon.png"
          alt="Home screen"
          sx={{ width: '40%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
    </>
  );

  const component = ios
    ? iosComponent
    : samsung
      ? samsungComponent
      : chromeComponent;

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }}>
      <Stack direction='row' spacing={1} alignItems='baseline'>
        <IconButton color='inherit' size='large' onClick={() => navigate(-1)} aria-label="go back">
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h4" gutterBottom>Установка приложения</Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant='body1'>
          Добавьте приложение на главный экран.
        </Typography>

        {component}

      </Stack>
    </Container>
  );
}

export default InstallApp;