import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';

const InstallPwaButton = () => {
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(true);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const installApp = async () => {
    if (!deferredPrompt) {
      navigate('/install-app');
      return;
    }

    deferredPrompt.prompt();

    setDeferredPrompt(null);
  };

  useEffect(() => {
    const handleAppInstalled = () => {
      console.log("Приложение установлено");
    };

    window.addEventListener("appinstalled", handleAppInstalled);

    return () => window.removeEventListener("appinstalled", handleAppInstalled);
  }, []);

  useEffect(() => {
    setIsInStandaloneMode(window.matchMedia('(display-mode: standalone)').matches);
  }, []);

  if (isInStandaloneMode) {
    return null;
  }

  return (
    <IconButton onClick={installApp} aria-label="download" color="primary">
      <GetAppRoundedIcon fontSize="large" />
    </IconButton>
  );
};

export default InstallPwaButton;
