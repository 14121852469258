// pages/User.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Avatar,
  Container,
  Alert,
  Checkbox,
  CardHeader,
  CardActions,
  IconButton,
  LinearProgress,
  TextField,
  FormControlLabel,
  Button,
  Link,
  CardMedia,
  Box,
} from '@mui/material';
import Slider from 'react-slick';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ShareIcon from '@mui/icons-material/Share';
import api from '../services/api';
import { useSelector } from 'react-redux';

const Master = () => {
  const { token } = useSelector((state) => state.auth);
  const { masterId } = useParams();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [ratingSubmitted, setRatingSubmitted] = useState(false);
  const [wouldRecommend, setWouldRecommend] = useState(true);
  const [comment, setComment] = useState('');

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const response = await api.get(`/masters/${masterId}`);

        setData(response.data);
      } catch (error) {
        setError(error.response?.data || error.message);
      }
    };

    if (masterId) {
      fetchRatings();
    }
  }, [masterId, ratingSubmitted]);

  const handleRatingSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      await api.post('/ratings', { ratedMasterId: masterId, wouldRecommend, comment });

      setRatingSubmitted(true);
    } catch (error) {
      setError(error.response?.data || error.message);
    }
  };

  const handleShare = () => {
    const url = process.env.REACT_APP_CLIENT_URL + "/master/" + masterId;

    if (navigator.share) {
      navigator.share({
        title: data?.name || 'ПрофиСфера',
        url: url,
      }).catch((error) => console.error('Error sharing:', error));
    } else {
      navigator.clipboard?.writeText(url).then(() => {
        alert('Ссылка скопирована в буфер обмена');
      }) || setError('Не удалось скопировать ссылку в буфер обмена');
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };

  if (!data) {
    return (
      <Container maxWidth="sm" sx={{ mt: 1 }} >
        <Stack direction='row' spacing={1} alignItems='baseline'>
          <IconButton color='inherit' size='large' onClick={() => navigate(-1)} aria-label="go back">
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography variant="h5" gutterBottom>Загрузка...</Typography>
        </Stack>
        <LinearProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }}>
      <Stack direction='row' spacing={1} alignItems='baseline'>
        <IconButton color='inherit' size='large' onClick={() => navigate(-1)} aria-label="go back">
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h5" gutterBottom noWrap>{data.name}</Typography>
      </Stack>
      <Stack spacing={1} sx={{ mt: 2 }}>
        {error && <Alert severity="error">{error}</Alert>}

        <Card>
          <CardHeader
            avatar={<Avatar
              sx={{ bgcolor: 'primary.light' }}
              src={data.photoUrl}
              alt={data.name} />}
            title={data.name}
          />
          {data.descriptionPhotos?.length > 0 &&
            <Slider {...settings}>
              {[...data.descriptionPhotos].sort((a, b) => a.order - b.order)
                .map((item, index) => (
                  <CardMedia
                    key={index}
                    component="img"
                    image={item.filePath}
                    alt={data.name} />
                ))}
            </Slider>}
          <CardContent>
            <Typography variant="body1" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
              {data.description || 'Описание отсутствует'}
            </Typography>
          </CardContent>
          <CardActions>
            {data.phoneNumber && <>
              <Link href={`tel:${data.phoneNumber}`} sx={{ mr: 1 }} variant="body1">
                +{data.phoneNumber}
              </Link> / <Link
                href={`https://wa.me/${data.phoneNumber}?text=Из%20profisfera.ru/master/${masterId}.%20`}
                variant="body1">
                WhatsApp
              </Link>
            </>}
            <Box sx={{ flexGrow: 1 }} />
            <IconButton aria-label="share" onClick={handleShare} >
              <ShareIcon />
            </IconButton>
          </CardActions>
        </Card>

        {token && (
          <>
            {!ratingSubmitted && (
              <Card>
                <CardHeader title="Оставить отзыв" />
                <CardContent>
                  <form onSubmit={handleRatingSubmit}>
                    <FormControlLabel
                      control={<Checkbox
                        defaultChecked
                        value={wouldRecommend}
                        onChange={(e) => setWouldRecommend(e.target.checked)} />}
                      label="Рекомендую" />
                    <TextField
                      label="Комментарий"
                      fullWidth
                      margin="normal"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      required
                      multiline
                    />
                    <Button variant='contained' type="submit">Оставить отзыв</Button>
                  </form>
                </CardContent>
              </Card>
            )}
            {ratingSubmitted && <Alert severity="success">Спасибо за отзыв!</Alert>}
          </>
        )}

        {data.ratings?.length > 0 ? (
          [...data.ratings]
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((rating) => (
              <Card key={rating.createdAt}>
                <CardHeader
                  avatar={<Avatar
                    sx={{ bgcolor: 'primary.light' }}
                    src={rating.reviewerPhotoUrl}
                    alt={`${rating.reviewerName}`} />}

                  title={`${rating.reviewerName}`}
                  subheader={rating.wouldRecommend ? 'Рекомендую' : undefined}
                  action={<Typography variant="caption" color="text.secondary">
                    {new Date(rating.createdAt).toLocaleDateString()}
                  </Typography>}
                />
                <CardContent>
                  <Typography variant="body1">{rating.comment}</Typography>
                </CardContent>
              </Card>
            ))
        ) : (
          <Typography>Нет отзывов</Typography>
        )}
      </Stack>
    </Container>
  );
}

export default Master;