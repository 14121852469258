import React, { useState, useEffect } from "react";
import { Paper, Box, IconButton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';

const InstallPwa = () => {
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(true);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const installApp = async () => {
    if (!deferredPrompt) {
      navigate('/install-app');
      return;
    }

    deferredPrompt.prompt();

    setDeferredPrompt(null);
  };

  useEffect(() => {
    const handleAppInstalled = () => {
      console.log("Приложение установлено");
    };

    window.addEventListener("appinstalled", handleAppInstalled);

    return () => window.removeEventListener("appinstalled", handleAppInstalled);
  }, []);

  useEffect(() => {
    setIsInStandaloneMode(window.matchMedia('(display-mode: standalone)').matches);
  }, []);

  if (isInStandaloneMode) {
    return null;
  }

  return (
    <Paper>
      <Stack direction={'row'} sx={{ p: 1 }} spacing={1} alignItems={"center"}>
        <Box
          component="img"
          src="/apple-touch-icon.png"
          alt="logo"
          width={48}
          height={48}
        />
        <Stack>
          <Typography variant="h6">ПрофиСфера</Typography>
          <Typography variant="caption">Сервис поиска мастеров с отзывами и уведомлениями</Typography>
        </Stack>
        <Box flexGrow={1} />
        <IconButton aria-label="download" size="large" color="secondary" onClick={installApp}>
          <GetAppRoundedIcon fontSize="inherit" />
        </IconButton>
      </Stack>
    </Paper>
  );
};

export default InstallPwa;
