import { TextField } from '@mui/material';
import React from 'react';
import InputMask from 'react-input-mask';

const PhoneInput = ({ value, onChange, required, inputRef }) => {
  return (
    <InputMask
      mask="+7 (999) 999-99-99"
      value={value}
      onChange={onChange}
    >
      {(inputProps) => <TextField
        {...inputProps}
        label="Телефон"
        variant="outlined"
        fullWidth
        margin="normal"
        type="tel"
        required={required}
        inputRef={inputRef}
      />}
    </InputMask>
  );
};

export default PhoneInput;
