// pages/ListingDetail.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListing } from '../slices/listingsSlice';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Alert,
  IconButton,
  Stack,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  CardHeader,
  CardMedia,
  CardActions,
  Link,
  Collapse,
  LinearProgress
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Offers from '../components/Offers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Category from '../components/Category';
import api from '../services/api';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

const ListingDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listing = useSelector((state) =>
    state.listings.listings.find((l) => l.id === id)
  );
  const { userId } = useSelector((state) => state.auth);

  const [error, setError] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    dispatch(fetchListing({ id }));
  }, [id, dispatch]);

  const handleCloseListing = async (listingId) => {
    try {
      await api.put(`/listings/${listingId}/close`, null);

      dispatch(fetchListing({ id }));
    } catch (error) {
      setError(error.message);
    }
  };

  const handleConfirmCloseListing = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleShare = () => {
    const url = process.env.REACT_APP_CLIENT_URL + "/listing/" + id;

    if (navigator.share) {
      navigator.share({
        title: listing?.title || 'ПрофиСфера',
        url: url,
      }).catch((error) => console.error('Error sharing:', error));
    } else {
      // Fallback for browsers that do not support the Web Share API
      navigator.clipboard?.writeText(url).then(() => {
        alert('Ссылка скопирована в буфер обмена');
      }) || setError('Не удалось скопировать ссылку в буфер обмена');
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };

  if (!listing) {
    return (
      <Container maxWidth="sm" sx={{ mt: 1 }}>
        <Stack spacing={1}>
          <Stack direction='row' spacing={1} alignItems='baseline'>
            <IconButton color='inherit' size='large' onClick={() => navigate(-1)} aria-label="go back">
              <ArrowBackIosNewIcon />
            </IconButton>
            <Typography variant="h4" gutterBottom noWrap>Загрузка...</Typography>
          </Stack>
          <LinearProgress />
          {error && <Alert severity="error">{error}</Alert>}
        </Stack>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }}>
      <Stack spacing={1}>
        <Stack direction='row' spacing={1} alignItems='baseline'>
          <IconButton color='inherit' size='large' onClick={() => navigate(-1)} aria-label="go back">
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography variant="h4" gutterBottom noWrap>{listing.title}</Typography>
        </Stack>
        <Card key={listing.id}>

          <CardHeader
            avatar={<Avatar
              sx={{ bgcolor: 'primary.light' }}
              src={listing.photoUrl}
              alt={listing.name} />}

            title={listing.name}
            action={<Typography variant="caption" color="text.secondary">
              {new Date(listing.createdAt).toLocaleDateString()}
            </Typography>}
          />

          {listing.photos?.length > 0 &&
            <Slider {...settings}>
              {[...listing.photos].sort((a, b) => a.order - b.order)
                .map((item, index) => (
                  <CardMedia
                    key={index}
                    component="img"
                    image={item.filePath}
                    alt={listing.title} />
                ))}
            </Slider>
          }

          <CardContent>
            <Typography variant="subtitle1" color="text.primary">
              Воспользуюсь услугой
            </Typography>
            <Typography variant="h6" color="text.primary">
              {listing.title}
            </Typography>
            <Category categoryId={listing.categoryId} />
            <Stack direction={'row'} alignItems="baseline" spacing={1}>
              {listing.askedPrice && <Typography variant="subtitle1" color="text.primary">
                {listing.askedPrice} руб.
              </Typography>}
              <Typography variant="body2" color="text.secondary">
                {listing.location}
              </Typography>
            </Stack>

            <Typography variant="body1" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
              {listing.description}
            </Typography>

            {listing.isClosed && <Alert sx={{ mt: 1 }} severity="info">Заказ закрыт</Alert>}
            {listing.advice && <Alert sx={{ mt: 1 }} severity="info">{listing.advice}</Alert>}
            {error && <Alert sx={{ mt: 1 }} severity="error">{error}</Alert>}
          </CardContent>

          <CardActions>

            {!listing.isClosed && listing.creatorId === userId &&
              <Button variant='contained' size="small" onClick={handleConfirmCloseListing}>Закрыть</Button>
            }

            {listing.phoneNumber && (<>
              <Link href={`tel:${listing.phoneNumber}`} sx={{ mr: 1 }} variant="body1">
                +{listing.phoneNumber}
              </Link> / <Link
                href={`https://wa.me/${listing.phoneNumber}?text=Из%20profisfera.ru/listing/${listing.id}.%20`}
                variant="body1">
                WhatsApp
              </Link>
            </>)}

            <Box sx={{ flexGrow: 1 }} />

            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardActions>
              <IconButton aria-label="share" onClick={handleShare} >
                <ShareIcon />
              </IconButton>
              {!listing.isClosed && listing.creatorId === userId &&
                <IconButton aria-label="edit" onClick={() => navigate(`/create-listing/${id}`)} >
                  <EditIcon />
                </IconButton>}
            </CardActions>
          </Collapse>
        </Card>
        <Offers listing={listing} />
      </Stack>
      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}>
        <DialogTitle>Подтверждение закрытия</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Вы уверены, что хотите закрыть этот заказ? Это действие нельзя будет отменить.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleConfirmDialogClose} color="primary">
            Отмена
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              handleCloseListing(listing.id);
              handleConfirmDialogClose();
            }}
            color="secondary"
            autoFocus>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ListingDetail;
