import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Container,
  Grid2,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  TextField,
  Link,
  // List,
  // ListItem,
  // ListItemIcon,
  // ListItemText,
  AppBar,
  Toolbar,
  Chip
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import InstallPwaButton from '../components/InstallPwaButton';

const Landing = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');

  return (
    <Box>
      {/* Header */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            ПрофиСфера
          </Typography>

          <Button color="inherit" href={process.env.REACT_APP_MASTER_URL}>Для мастеров</Button>
          <Button color="inherit" onClick={() => navigate('/profile')}>Войти</Button>
        </Toolbar>
      </AppBar>

      {/* Hero Section */}
      <Box sx={{ textAlign: 'center', py: 8, backgroundColor: '#f5f5f5' }}>
        <Container maxWidth="md">
          <Typography variant="h3" gutterBottom>
            Найдите проверенных мастеров бытовых услуг в&nbsp;Якутске
          </Typography>
          <Typography variant="h6" color="textSecondary" paragraph>
            Опишите задачу — получите отклики от&nbsp;мастеров и&nbsp;выберите по&nbsp;рекомендациям.
            <br />
            Легко, быстро, надёжно.
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 4 }}>
            <TextField
              label="Что нужно сделать?"
              variant="outlined"
              size="small"
              value={title}
              onChange={(e) => setTitle(e.target.value)}

              sx={{ width: 300, mr: 2 }}
            />
            <Button variant="contained" color="primary" endIcon={<SearchIcon />}
              onClick={() => navigate(`/create-listing?title=${encodeURIComponent(title)}`)}>
              Найти мастера
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Categories */}
      <Container sx={{ py: 8 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Категории услуг
        </Typography>
        <Grid2 container spacing={2} justifyContent="center">
          {[
            // 'Строительство и ремонт',
            'Сантехники',
            'Электрики',
            'Ремонт, установка техники',
            'Грузчики',
            // 'Спецтехника',
            // 'Транспортировка',
            // 'Автосервис',
            // 'Бухгалтерия и финансы',
            // 'Детские сады, няни, сиделки',
            // 'Красота и здоровье',
            // 'Курсы, семинары, тренинги',
            'Мастер на час',
            // 'Мебель, ремонт и изготовление',
            // 'Обучение, репетиторство',
            // 'Пошив, ремонт одежды, обуви',
            // 'Праздники и мероприятия',
            // 'Работа с текстом, переводы',
            // 'Реклама и полиграфия',
            // 'Ремонт компьютеров, мобильных устройств',
            // 'Слесари',
            // 'Создание сайтов, поддержка ПО',
            'Уборка помещений, территорий',
            // 'Фото- и видеосъёмка',
            // 'Юридические услуги',
            // 'Без категории',
          ].map((category, index) => (
            <Grid2 key={index}>
              <Chip label={category} color="primary" sx={{ color: 'text.primary' }} variant="outlined" />
            </Grid2>
          ))}
        </Grid2>
      </Container>

      {/* How It Works */}
      <Box sx={{ py: 8, backgroundColor: '#f9f9f9' }}>
        <Container>
          <Typography variant="h4" align="center" gutterBottom>
            Как работает сервис
          </Typography>
          <Grid2 container spacing={4}>
            {[
              { title: 'Создайте задачу', text: 'Опишите нужную услугу и выберите категорию из списка' },
              { title: 'Получите отклики', text: 'Пусть профессионалы сами предложат свои услуги и цены' },
              { title: 'Выберите мастера', text: 'Оценивайте мастеров по реальным отзывам' }
            ].map((step, index) => (
              <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={index}>
                <Card sx={{ textAlign: 'center', p: 2 }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {step.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {step.text}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid2>
            ))}
          </Grid2>
        </Container>
      </Box>

      {/* Popular Masters and Reviews */}
      <Container sx={{ py: 8 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Популярные мастера
        </Typography>
        <Grid2 container spacing={4}>
          {[
            {
              id: '23e662e3-30be-48f6-815e-16f0cf2521cd',
              name: 'Сергей',
              desc: 'ВИДЕОНАБЛЮДЕНИЕ БЫСТРО, КАЧЕСТВЕННО И НЕДОРОГО!!! Для безопасности - вас и ваших близких, сотрудников, клиентов, имущества и бизнеса, а также для разрешения спорных вопросов.',
              img: '/img/8ca061a5-8d55-4e7f-b174-94ebb31567c0'
            },
            {
              id: '597183fc-906a-48ff-b0aa-ee040d4001c3',
              name: 'Павел',
              desc: '⚡️МАСТЕР НА ДОМ⚡️ 👋🏽Всем привет, меня зовут Павел!) Работаю сам, не компания! Адекватные цены. Гарантия на все услуги. Работаю все районы города Буду рад сотрудничеству🤝',
              img: '/img/99e79b7e-daf0-4a2d-be26-8a924d6e1f8d'
            },
            {
              id: '3d1f2741-5777-46c1-bbc1-a8ad4255a9d0',
              name: 'Сахавольт',
              desc: 'Услуги электриков Саха. ВВОД СО СТОЛБА. Сборка ЩИТА ПО ТЕХ.УСЛОВИЮ ЯКУТСКЭНЕРГО. 💡 Готовые работы в Telegram https://t.me/sakhavolt11. 🔌Монтаж Домов,квартир. Под ключ 🔑. Материал соответствует 💯 ГОСТ.',
              img: '/img/0cc0cba2-30ac-4a9d-89a1-3f054cd32574'
            },
          ].map((item, index) => (
            <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Card>
                <CardActionArea component={RouterLink} to={`/master/${item.id}`}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={item.img}
                    alt={item.name}
                  />
                  <CardContent>
                    <Typography variant="h6">{item.name}</Typography>
                    <Typography variant="body2" color="textSecondary" paragraph>
                      {item.desc}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </Container>

      {/* App Installation Section */}
      <Box sx={{ textAlign: 'center', py: 8, backgroundColor: '#f0f4f8' }}>
        <Container>
          <Typography variant="h4" gutterBottom>
            Установите приложение за&nbsp;несколько кликов
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Следуйте инструкциям, чтобы установить приложение на&nbsp;ваш телефон без магазина приложений.
          </Typography>
          <InstallPwaButton />
        </Container>
      </Box>

      {/* FAQ */}
      {/* <Container sx={{ py: 8 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Часто задаваемые вопросы
        </Typography>
        <List>
          <ListItem key={0}>
            <ListItemIcon>
              <StarIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Как выбрать мастера по отзывам?" secondary="ответ1" />
          </ListItem>
          <ListItem key={1}>
            <ListItemIcon>
              <StarIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Как мне оставить заявку?" secondary="ответ2" />
          </ListItem>
          <ListItem key={2}>
            <ListItemIcon>
              <StarIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Безопасно ли пользоваться сервисом?" secondary="ответ3" />
          </ListItem>
        </List>
      </Container> */}

      {/* Sign-In Section */}
      <Box sx={{ py: 8, textAlign: 'center', backgroundColor: '#e8f5e9' }}>
        <Container>
          <Typography variant="h4" gutterBottom>
            Начните работать с&nbsp;нами сегодня
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Создайте свой аккаунт и&nbsp;начните находить лучших специалистов для любых задач.
          </Typography>
          <Button variant="contained" color="primary" size="large" onClick={() => navigate('/profile')}>
            Войти / Регистрация
          </Button>
        </Container>
      </Box>
    </Box>
  );
};

export default Landing;