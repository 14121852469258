// components/Navbar.js
import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import { useLocation, useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Paper, ThemeProvider, createTheme } from '@mui/material';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const navTheme = createTheme({
    palette: {
      primary: {
        main: '#fff'
      },
      secondary: {
        main: '#03a9f4',
        contrastText: '#fff',
      },
      text: {
        secondary: '#ffffff80'
      },
      background: {
        paper: '#ff5722'
      }
    }
  });

  const ios = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
  const inStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;
  const withSafeArea = ios && inStandaloneMode;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleNavigationChange = (event, newValue) => {
    scrollToTop();
    if (newValue === 0) {
      navigate('/listings');
    } else if (newValue === 1) {
      navigate('/create-listing');
    } else if (newValue === 2) {
      navigate('/profile');
    }
  };

  return (
    <ThemeProvider theme={navTheme}>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          sx={{ height: withSafeArea ? 80 : undefined }}
          value={
            location.pathname === '/listings'
              ? 0
              : location.pathname === '/create-listing'
                ? 1
                : location.pathname === '/profile'
                  ? 2
                  : undefined}
          onChange={handleNavigationChange}>
          <BottomNavigationAction sx={{ pb: withSafeArea ? 3 : undefined }} label="Мои заказы" icon={<ViewDayIcon />} />
          <BottomNavigationAction sx={{ pb: withSafeArea ? 3 : undefined }} label="Создать заказ" icon={<AddIcon />} />
          <BottomNavigationAction sx={{ pb: withSafeArea ? 3 : undefined }} label="Профиль" icon={<PersonIcon />} />
        </BottomNavigation>
      </Paper>
    </ThemeProvider>
  );
};

export default Navbar;
